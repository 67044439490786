import React from "react";
import { graphql } from "gatsby";

import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import SiteMetadata from "../components/SiteMetadata";
import Layout from "../layouts/Layout";
import ArticleList from "../components/ArticleList";

import { GatsbyImage } from "gatsby-plugin-image";

import BMC from "../../static/api.jpeg";

export default function InstructionPage(props) {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <div className="font-bold">{text}</div>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-2xl font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h1 className="text-2xl font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h1 className="text-xl font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h1 className="text-lg font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => children,
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="flex">
          <span className="px-2">-</span> {children}
        </li>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="font-semibold text-red-700"
          href={node.data.uri}
          target="__blank"
        >
          {children}
        </a>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <div className="p-12 ">
            <GatsbyImage image={node.data.target.gatsbyImageData} alt="image" />
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (
          node.data.target["__typename"]?.startsWith("ContentfulDownloadButton")
        )
          return DownloadField(node.data.target);
        return <div>{JSON.stringify(node.data.target)}</div>;
      },
    },
  };

  return (
    <Layout>
      <SiteMetadata
        title={`${props.data.item.title} - Anleitung 6 Tipps 🤩`}
        description={`${props.data.item.title} - Mach es nach! Hier ist die Anleitung für die Geschenkidee ${props.data.item.title}!`}
        image={`${props.data.item.image.file.url}`}
      />
      <div className="w-full mx-auto 2xl:max-w-screen-xl 2xl:px-8">
        <div
          className="flex flex-wrap justify-center items-center mb-8 2xl:rounded-lg "
          style={{
            backgroundImage: `url(${props.data.item.image.file.url})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
        >
          <h2 className="py-40 font-display text-4xl sm:text-6xl leading-tight tracking-tight text-white text-center">
            {props.data.item.title}
            <span className="text-yellow-500">.</span>
          </h2>
        </div>
      </div>

      <div className="container pb-8">
        <div className="max-w-screen-md mx-auto">
          {props.data.item.description.description}
          {renderRichText(props.data.item.body, options)}
        </div>
      </div>

      <ArticleList
        event={
          props.data.item.idee ? props.data.item.idee[0]?.event[0].title : null
        }
        person={
          props.data.item.idee
            ? props.data.item.idee[0]?.person[0].title.title
            : null
        }
      />
    </Layout>
  );
}

const DownloadField = (target) => {
  const submit = target.title;
  return (
    <div className="p-4 bg-gray-100 rounded-lg my-4">
      <button
        type="submit"
        className="bg-yellow-500 text-white font-bold p-4 rounded-2xl hover:bg-red-500 transition duration-300"
      >
        {submit}
      </button>
      <div className="py-4 font-display">
        Falls es Dir gefällt, würden wir uns auch über eine Spende freuen!
      </div>
      <a
        href="https://www.buymeacoffee.com/handverlesen"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-96 rounded-lg" alt="Buy me a coffee" src={BMC} />
      </a>
    </div>
  );
};

export const query = graphql`
  query InstructionQuery($title: String!) {
    item: contentfulInstruction(title: { eq: $title }) {
      title
      description {
        description
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
          ... on ContentfulDownloadButton {
            contentful_id
            __typename
            title
            download {
              file {
                url
              }
            }
          }
        }
      }
      idee {
        event {
          title
        }
        person {
          title {
            title
          }
        }
      }
      image {
        file {
          url
        }
      }
    }
  }
`;
